<template>
  <no-ssr>
  <div class="sub-menu microcart flex absolute right-0 top-full border-10 border-grey-30 p-0" v-if="!isCartOrCheckout">
    <div
      class="ml-auto bg-black pointer-events-auto z-30"
      data-testid="microcart"
    >
      <div v-if="productsInCart.length">
        <template v-if="productsInCart.length">
          <div class="microcart__products" :style="miniCartContentHeightStyles">
            <div class="product-group" v-if="groupedProducts.rco.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('R+Co') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-rco" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.rco" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.rco)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('R+Co Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.rco" :key="uid(product) + 'loyalty'" :product="product" />
              </ul>
            </div>
            <div class="product-group" v-if="groupedProducts.rco_bleu.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('R+Co Bleu') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-rco-bleu" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.rco_bleu" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.rco_bleu)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('R+Co Bleu Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.rco_bleu" :key="uid(product) + 'loyalty'" :product="product" />
              </ul>
            </div>
            <div class="product-group" v-if="groupedProducts.rco_color.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('R+Color') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-rco-bleu" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.rco_color" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.rco_color)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('R+Color Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.rco_color" :key="uid(product) + 'loyalty'" :product="product" />
              </ul>
            </div>
            <div class="product-group" v-if="groupedProducts.v76.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('V76 by Vaughn') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-v76" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.v76" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.rco_color)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('V76 Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.v76" :key="uid(product) + 'loyalty'" :product="product" />
              </ul>
            </div>
            <div class="product-group" v-if="groupedProducts.sc.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('Smith \& Cult') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-sc" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.sc" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.sc)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('S\&C Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.sc" :key="uid(product) + 'loyalty'" :product="product" />
              </ul>
            </div>
            <div class="product-group" v-if="groupedProducts.unclassified.length">
              <span class="block text-white font-bold uppercase text-h5 leading-tight tracking-average py-3 pl-5">
                {{ $t('Unclassified') }}
              </span>
              <ul class="list-none microcart-products-list" id="microcart-products-list-unclassified" ref="microcartProductsList">
                <product v-for="(product) in groupedProducts.unclassified" :key="uid(product)" :product="product" />
                <li v-if="usingPoints(groupedProducts.unclassified)">
                  <span class="block bg-grey-12 text-white uppercase text-h5 leading-tight tracking-average py-1 pl-5">
                    {{ $t('Unclassified Reward Items') }}
                  </span>
                </li>
                <product-loyalty v-for="(product) in groupedProducts.unclassified" :key="uid(product)  + 'loyalty'" :product="product" />
              </ul>
            </div>
          </div>
        </template>
        <div class="bg-white py-md px-7">
          <div class="mb-sm relative flex items-center">
            <span class="flex-1 text-dark tracking-mid uppercase text-h6 leading-h3 font-sans inline-block text-left">
              {{ $t('Cart total') }}
            </span>
            <span class="flex-1 text-2h6 text-dark leading-h3 tracking-thick font-serif italic inline-block self-right text-right"
            >
              {{ brandsTotal | price }} <span v-if="productsInCart.length"><br>+ <span>&diams; </span>{{ (brandsLoyaltyTotal || 0).toFixed(2) }} {{ $t('Pts')}}</span>
            </span>
          </div>
          <router-link
            :to="localizedRoute({ name: 'cart' })" :title="$t('Cart')"
            @click.native="() => closeMicrocart()"
            class="block items-center text-h5 w-full outline-none rounded-0 border-0 text-white uppercase leading-60px tracking-sm pl-10 pr-5 bg-primary hover:bg-brown-2"
          >
            <span class="leading-60px">{{ $t('Checkout') }}</span>
            <i class="ss-icon ss-navigateright leading-60px pt-px float-right"></i>
          </router-link>
          <free-shipping-bar :subtotal="brandsTotal" />
        </div>
<!--        <div class="pro_same_day text-grey-41 bg-grey-9 relative uppercase py-md pr-5 pl-17" v-if="isSameDayShippingAvailable">-->
<!--          <strong class="text-green text-h5 leading-loose tracking-md font-sans font-black">Get it Faster!</strong>-->
<!--          <p class="text-sm leading-loose tracking-mid mt-1 mb-3">Orders placed before <span id="pro_lc">4PM</span> <span id="pro_tz">Time</span> will be processed the same day. Don’t miss out!</p>-->
<!--          <div id="pro_countdown" class="text-h6 leading-h6 tracking-mid overflow-hidden">-->
<!--            <span class="pro_hrs">{{ hoursLeft }}</span> Hrs | <span class="pro_mins">{{ minutesLeft }}</span> Mins | <span class="pro_secs">{{ secondsLeft }}</span> Secs-->
<!--          </div>-->
<!--          <i class="ss-icon ss-stopwatch"></i>-->
<!--        </div>-->
      </div>
      <div v-else-if="!cartIsLoaded" style="height: 130px" class="bg-white flex items-center justify-center text-dark font-sans uppercase text-center text-sm leading-xs tracking-mid py-xl">
        Loading...<br>
        <loader class="inline-block" :color="`#000`" aria-label="Loading" />
      </div>
      <div v-else class="bg-grey-9 text-dark font-sans uppercase text-center text-sm leading-xs tracking-mid py-xl">
        {{ $t('There are no items in your cart') }}
      </div>
    </div>
  </div>
  </no-ssr>
</template>

<script>
import Microcart from '@vue-storefront/core/compatibility/components/blocks/Microcart/Microcart'
import VueOfflineMixin from 'vue-offline/mixin'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import Product from 'theme/components/core/blocks/Microcart/Product'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import Cart from '../../../../mixins/pages/Cart'
import { setInterval, clearInterval } from 'timers'
import ProductLoyalty from 'theme/components/core/blocks/Microcart/ProductLoyalty'
import NoSSR from 'vue-no-ssr'
import Loader from 'theme/components/theme/Loader'
import FreeShippingBar from 'theme/components/theme/blocks/Cart/FreeShippingBar'
import { mapState } from "vuex";

export default {
  components: {
    Loader,
    ProductLoyalty,
    Product,
    FreeShippingBar,
    'no-ssr': NoSSR
  },
  mixins: [
    Microcart,
    VueOfflineMixin,
    onEscapePress,
    Cart,
    PriceRules
  ],
  data () {
    return {
      componentLoaded: false,
      miniCartContentHeight: 120,
      hoursLeft: 0,
      minutesLeft: 0,
      secondsLeft: 0
    }
  },
  props: {
    isCheckoutMode: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  computed: {
    ...mapState({
      actionInProgress: state => state.cart.processing
    }),
    grandTotal () {
      for (let i = 0; i < this.totals.length; i++) {
        const segment = this.totals[i]

        if (segment.code === 'grand_total') {
          return segment
        }
      }
      return null
    },
    miniCartContentHeightStyles () {
      let styleObject = {}
      let heightList = 0

      if (typeof this.$refs.microcartProductsList !== 'undefined') {
        heightList = this.$refs.microcartProductsList.clientHeight
      }
      if (heightList > this.miniCartContentHeight) {
        styleObject['overflow-y'] = 'scroll'
        styleObject['max-height'] = this.miniCartContentHeight + 'px'
      }
      return styleObject
    },
    routerInfo () {
      return this.$route
    },
    isCartOrCheckout () {
      return !this.$isServer && (this.$route.name === 'cart' || this.$route.name === 'checkout')
    },
    cartOrCheckoutUrl () {
      return (this.$route.name === 'cart') ? 'checkout' : 'cart'
    },
    isSameDayShippingAvailable () {
      const now = new Date(Date.now())

      return now.getHours() < 16 && now.getHours() > 5
    }
  },
  beforeMount () {
    this.$bus.$on('microcart-close', () => {
      this.closeMicrocart()
    })
    this.sameDayShippingCountdown()
  },
  mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true
      this.calculateMiniCartHeight()
    })
    this.calculateMiniCartHeight()
  },
  beforeDestroy () {
  },
  methods: {
    closeMicrocart () {
      this.$store.commit('ui/setMicrocart', false)
    },
    onEscapePress () {
      this.closeMicrocart()
    },
    calculateMiniCartHeight () {
      let windowH = window.innerHeight

      this.miniCartContentHeight = windowH / 3
    },
    sameDayShippingCountdown () {
      const today = new Date(Date.now())
      const endTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16, 0, 0, 0)
      const endTimeInSeconds = endTime.getTime()

      let intervalTimer

      intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((endTimeInSeconds - Date.now()) / 1000)

        if (secondsLeft < 0) {
          clearInterval(intervalTimer)
          return
        }
        this.displaySameDayShippingTimeLeft(secondsLeft)
      }, 1000)
    },
    displaySameDayShippingTimeLeft (time) {
      const hours = Math.floor((time % 3600) / 60 / 60)
      const minutes = Math.floor((time % 3600) / 60)
      const seconds = time % 60

      this.hoursLeft = `${this.padZero(hours)}`
      this.minutesLeft = `${this.padZero(minutes)}`
      this.secondsLeft = `${this.padZero(seconds)}`
    },
    padZero (num) {
      return num < 10 ? `0${num}` : num
    }
  }
}
</script>

<style lang="scss" scoped>
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .microcart {
    @apply hidden;
    width: 330px;

    @screen xl {
      @apply block;
    }

    &__products {
      @apply overflow-auto;
      max-height: 350px;
    }

    .pro_same_day {
      i {
        @apply absolute text-2md leading-none;
        top: 26px;
        left: 20px;
      }
      #pro_countdown {
        > span {
          @apply font-bold inline-block overflow-y-hidden;
          height: 14px;

          span {
            @apply block;
            height: 14px;
          }
        }
      }
    }
  }
</style>
