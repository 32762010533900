<template>
  <li v-if="isStandardItem && standardVisible" class="product block bg-grey-11 flex flex-nowrap items-center text-dark py-3 px-5">
    <div class="text-center w-1/3 md:w-1/6">
      <router-link
        :to="productLink(product)"
      >
        <img
          class="image max-h-thumb-s"
          v-lazy="thumbnail"
          :alt="product.name"
        >
      </router-link>
    </div>
    <div class="w-2/3 md:w-5/6 flex flex-wrap items-center">
      <div class="w-2/3 px-3">
        <router-link
          :to="productLink(product)"
          class="font-medium"
        >
          <p class="text-dark text-h4 leading-base" v-if="configurableProductInfo">{{ configurableProductInfo.label | stripHTML }}: {{ configurableProductInfo.value | stripHTML }}</p>
          <p class="text-grey-2 text-h4 leading-base" v-if="configurableProductInfo">{{ product.name | stripHTML }}</p>

          <p class="text-dark text-h4 leading-base" v-if="!configurableProductInfo">{{ product.name | stripHTML }}</p>
          <p class="text-grey-2 text-h4 leading-base" v-if="!configurableProductInfo">{{ product.subtitle | stripHTML }}</p>
        </router-link>
        <div v-if="!rulePrice">
          <product-price :product="product" :is-total="true" />
        </div>
        <div class="prices" v-else>
          <span class="text-sm">
            {{ rulePrice * getQuantity | price }}
          </span>
        </div>
      </div>
      <div class="w-1/3">
        <base-quantity-input
          :min="incrementStep"
          :name="$t('Quantity')"
          :readonly="true"
          @input="updateQuantity"
          :increment="incrementStep"
          :value="rawQty"
          :loading="isLoading"
          :classes="{'bg-primary2': isLoading}"
          ref="qtyInput"
          @remove="removeItem"
        />
<!--        <div class="text-center">-->
<!--          <loader v-show="isLoading" color="text-black" />-->
<!--        </div>-->
<!--        <a v-if="!isLoading" href="#" @click.prevent="removeItem" class="block mt-2"><i class="ss-icon ss-trash"></i></a>-->
      </div>
    </div>
  </li>
</template>

<script>
import config from 'config'
import Product from 'theme/mixins/blocks/Product'
import ProductPrice from 'theme/components/theme/ProductPrice'
import PriceRules from 'theme/mixins/blocks/PriceRules'
import BaseQuantityInput from 'theme/components/core/blocks/Form/BaseQuantityInput'
import {productThumbnailPath} from '@vue-storefront/core/helpers'

export default {
  components: {
    BaseQuantityInput,
    ProductPrice
  },
  mixins: [
    Product,
    PriceRules
  ],
  data () {
    return {
      renderComponent: true
    }
  },
  methods: {
    forceRerender () {
      // Removing my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
    }
  },
  computed: {
  }
}
</script>

<style lang="scss">
.microcart-products-list {
  .product {
    .prices {
      span {
        @apply text-h4 font-normal leading-h5 text-black italic font-serif;
      }
    }
    .quantity-select-action {
      input[type='number'] {
        padding: 0;
        text-align: center;
        border: none;
      }
      button {
        border: none;
        height: 26px;
        width: 26px;
        margin: 0 2px;
        padding-top: 6px;
        // display: none!important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.product {
  .quantity-select-action {
    input[type='number'] {
      padding: 0;
    }
  }
  &+ .product {
    @apply border-t border-grey-42;
  }
}
</style>
